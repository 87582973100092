import React, { useEffect, useState } from "react";
import "./Creations.scss";
import Navbar from "./Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import imagesData from "./creations.json";
import { useTranslation } from 'react-i18next';

function Creations() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ offset: -10600 });
  }, []);

  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedYear, setSelectedYear] = useState('all');

  const handleFilterClick = (category) => {
    setSelectedCategory(category);
  };

  const handleYearFilterClick = (year) => {
    setSelectedYear(year);
  };

  const getButtonStyle = (year) => {
    if (year === selectedYear) {
      return {
        flex: '1.2',
        transition: 'flex 0.3s ease-in-out'
      };
    } else {
      return {
        flex: '0.8',
        transition: 'flex 0.3s ease-in-out'
      };
    }
  };

  const filteredImages = imagesData.filter(image => {
    const categoryCondition = selectedCategory === 'all' || image.category.some(cat => cat.toLowerCase() === selectedCategory.toLowerCase());
    const yearCondition = selectedYear === 'all' || image.creationDate.split('/')[2] === selectedYear;
    return categoryCondition && yearCondition;
  });

  return (
    <div className="creations-page">
      <Navbar />
      <div className="particle particle-1" data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="1500"></div>
      <div className="particle particle-2" data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="2000"></div>
      <div className="particle particle-3" data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="2500"></div>
      <div className="particle particle-4" data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="3000"></div>

      <div className="filter-container">
        <div className="year-buttons" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="600">
          <button style={getButtonStyle('all')} className={selectedYear === 'all' ? 'active' : ''} onClick={() => handleYearFilterClick('all')}>All</button>
          <button style={getButtonStyle('2021')} className={selectedYear === '2021' ? 'active' : ''} onClick={() => handleYearFilterClick('2021')}>2021</button>
          <button style={getButtonStyle('2022')} className={selectedYear === '2022' ? 'active' : ''} onClick={() => handleYearFilterClick('2022')}>2022</button>
          <button style={getButtonStyle('2023')} className={selectedYear === '2023' ? 'active' : ''} onClick={() => handleYearFilterClick('2023')}>2023</button>
          <button style={getButtonStyle('2024')} className={selectedYear === '2024' ? 'active' : ''} onClick={() => handleYearFilterClick('2024')}>2024</button>
        </div>
      </div>

      <main className='gallery'>
        <div className='gallery-track'>
          {filteredImages.map((image, index) => (
            <div className='card' key={index} data-aos="fade-up" data-aos-duration="1000" data-aos-delay={(100 * index)}>
              <div className='card-image-wrapper'>
                <a href={image.url} target="_blank" rel="noopener noreferrer">
                  <img src={image.src} alt={image.alt} className="image-class" />
                </a>
                <div className="info-overlay">
                  <strong>{image.title}</strong>
                  <span>{t('creation')}{image.creationDate}</span>
                  <div className="tech-used-container">
                    <div className="tech-icons">
                      {image.technologies.map((tech, techIndex) => (
                        <img key={techIndex} src={tech.src} alt={tech.alt} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}

export default Creations;
