import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { useTheme } from './ThemeContext';

const ModelViewer = ({ rotationSpeed = 0.0005 }) => {
  const mountRef = useRef(null);
  const modelsRef = useRef({ light: null, dark: null });
  const rotationRef = useRef({ x: 0, y: 0, z: 0 });
  const [loaded, setLoaded] = useState(false);
  const { themeMode } = useTheme();

  useEffect(() => {
    const mount = mountRef.current;
    const scene = new THREE.Scene();
    scene.background = null;

    const camera = new THREE.PerspectiveCamera(705, mount.clientWidth / mount.clientHeight, 0.1, 1000);
    camera.position.z = 5;

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(mount.clientWidth, mount.clientHeight);
    mount.appendChild(renderer.domElement);

    const ambientLight = new THREE.AmbientLight(0x404040, 15);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 10);
    directionalLight.position.set(0, 10, 10).normalize();
    scene.add(directionalLight);

    const loader = new GLTFLoader();

    const loadModel = (path, key) => {
      return new Promise((resolve, reject) => {
        loader.load(
          path,
          (gltf) => {
            const model = gltf.scene;
            model.rotation.set(rotationRef.current.x, rotationRef.current.y, rotationRef.current.z);
            scene.add(model);
            modelsRef.current[key] = model;
            resolve();
          },
          undefined,
          (error) => {
            console.error('An error happened', error);
            reject(error);
          }
        );
      });
    };

    Promise.all([
      loadModel('/models/scene.gltf', 'light'),
      loadModel('/models/scene2.gltf', 'dark')
    ]).then(() => {
      modelsRef.current.light.visible = themeMode === 'light';
      modelsRef.current.dark.visible = themeMode === 'dark';
      setLoaded(true); // Mark models as loaded
      animate();
    });

    const animate = () => {
      requestAnimationFrame(animate);
      if (modelsRef.current.light && modelsRef.current.dark) {
        modelsRef.current.light.rotation.y += rotationSpeed;
        modelsRef.current.dark.rotation.y += rotationSpeed;
        rotationRef.current = { 
          x: modelsRef.current.light.rotation.x, 
          y: modelsRef.current.light.rotation.y, 
          z: modelsRef.current.light.rotation.z 
        };
      }
      renderer.render(scene, camera);
    };

    const handleResize = () => {
      renderer.setSize(mount.clientWidth, mount.clientHeight);
      camera.aspect = mount.clientWidth / mount.clientHeight;
      camera.updateProjectionMatrix();
    };
    window.addEventListener('resize', handleResize);

    return () => {
      mount.removeChild(renderer.domElement);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (modelsRef.current.light && modelsRef.current.dark) {
      modelsRef.current.light.visible = themeMode === 'light';
      modelsRef.current.dark.visible = themeMode === 'dark';
    }
  }, [themeMode]);

  return (
    <div 
      ref={mountRef} 
      style={{
        position: 'absolute',
        bottom: '50px',
        right: '0',
        width: 'calc(100% - 1px)',
        height: 'calc(100% - 150px)',
        zIndex: 1,
        opacity: loaded ? 1 : 0,
        transition: 'opacity 1s ease-in-out'
      }} 
    />
  );
};

export default ModelViewer;
