import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as HomepageIcon } from "./img/homepage1.svg";
import frenchIcon from "./img/french.png";
import englishIcon from "./img/english.png";
import koreanIcon from "./img/korean.png";
import japaneseIcon from "./img/japanese.png";
import brightIcon from "./img/bright.png";
import darkIcon from "./img/dark.png";
import { useTheme } from './ThemeContext';  // Importer le contexte du thème
import "./MainContent.scss";

function Navbar() {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState(i18n.resolvedLanguage);
    const { themeMode, toggleDarkMode } = useTheme();  // Utiliser le contexte du thème

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const changeLanguage = (language) => {
        i18n.changeLanguage(language).then(() => {
          setCurrentLanguage(language);
          localStorage.setItem('preferredLanguage', language);
          console.log("Language changed to: " + language);
        });
    };

    useEffect(() => {
        const savedLanguage = localStorage.getItem('preferredLanguage') || 'en';
        changeLanguage(savedLanguage);
    }, []);

    const allFlags = {
        en: englishIcon,
        fr: frenchIcon,
        kr: koreanIcon,
        jp: japaneseIcon,
    };

    const currentFlagIcon = allFlags[currentLanguage];
    const otherFlags = Object.entries(allFlags).filter(([lang, _]) => lang !== currentLanguage);

    function handleMouseEnter() {
        document.querySelector(".nav-left").classList.add("nav-left-hovered");
        document.querySelector(".homepage-icon").classList.add("homepage-icon-hovered");
    }

    function handleMouseLeave() {
        document.querySelector(".nav-left").classList.remove("nav-left-hovered");
        document.querySelector(".homepage-icon").classList.remove("homepage-icon-hovered");
    }

    const themeIcon = themeMode === 'light' ? brightIcon : darkIcon;

    return (
        <>
        <nav className="navbar">
            <div className="hamburgerdiv" data-aos="fade-up" data-aos-duration="1200">
                <div className={`hamburger-menu ${isMenuOpen ? "active" : ""}`} onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div className="nav-left">
                <Link to="/"><HomepageIcon className="homepage-icon" alt="Homepage" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}  data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200"/></Link>
            </div>
            <div className="nav-middle">
                <Link className={`underline ${location.pathname === '/about' ? 'active-link' : ''}`} to="/about" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">{t('about')}</Link>
                <Link className={`underline ${location.pathname === '/projects' ? 'active-link' : ''}`} to="/projects" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">{t('projects')}</Link>
                <Link className={`underline ${location.pathname === '/creations' ? 'active-link' : ''}`} to="/creations" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="500">{t('creations')}</Link>
                <Link className={`underline ${location.pathname === '/contact' ? 'active-link' : ''}`} to="/contact" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="600">{t('contact')}</Link>
            </div>
            <div className="nav-right flag-container">
                <img src={currentFlagIcon} alt="Current Language" className="flag-current" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400"/>
                <div className="flags-menu">
                    {otherFlags.map(([lang, icon], index) => (
                        <div key={lang} className="flag-item" onClick={() => changeLanguage(lang)} style={{animationDelay: `${index * 0.1}s`}}>
                            <img src={icon} alt={lang} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="nav-fourth">
                <button className="dark-mode-toggle" onClick={toggleDarkMode} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="600">
                    <img src={themeIcon} alt="Toggle Dark Mode" />
                </button>
            </div>
            <div className="navtitre">
                <h1 data-aos="zoom-out" data-aos-duration="1500" data-aos-delay="800">Thibault Delattre</h1>
                <h2  data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="1000">{t('fullstack')}</h2>
            </div>
            {isMenuOpen && (
                <div className="nav-menu-main">
                    <Link to="/about">{t('about')}</Link>
                    <Link to="/projects">{t('projects')}</Link>
                    <Link to="/creations">{t('creations')}</Link>
                    <Link to="/contact">{t('contact')}</Link>
                </div>
            )}
        </nav>
        </>
    );
}

export default Navbar;
