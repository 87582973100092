import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Loader from './Loader';
import MainContent from './MainContent';
import About from './About';
import Projects from './Projects';
import Creations from './Creations';
import Contact from './Contact';
import { ThemeProvider } from './ThemeContext';
import './index.scss';

function App() {
  const [loading, setLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const fadeOutDelay = 3800;
    const loaderDuration = 0;

    const timer = setTimeout(() => {
      setLoading(false);

      const fadeOutTimer = setTimeout(() => {
        setShowLoader(false);
      }, 1000);

      return () => clearTimeout(fadeOutTimer);
    }, loaderDuration + fadeOutDelay);

    return () => clearTimeout(timer);
  }, []);

  return (
    <ThemeProvider>
    <Router>
      {showLoader && <Loader show={loading} />}
      {!loading && (
        <div className="App">
          <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/creations" element={<Creations />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      )}
    </Router>
    </ThemeProvider>
  );
}

export default App;
