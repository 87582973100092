  import React, { useEffect, useState } from "react";
  import "./Contact.scss";
  import Navbar from "./Navbar";
  import frenchIcon from "./img/french.png";
  import englishIcon from "./img/english.png";
  import AOS from "aos";
  import "aos/dist/aos.css";
  
  function Contact() {
    useEffect(() => {
      AOS.init({});
    }, []);
  
    return (
      <div className="contact-page">
        <Navbar />
        <div className="particle particle-1" data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="1500"></div>
        <div className="particle particle-2" data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="2000"></div>
        <div className="particle particle-3" data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="2500"></div>
        <div className="particle particle-4" data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="3000"></div>

        <main className="maincontact">
          <div className="containerbloc">

          <div className="contact-block">
            <img className="icon" src="/mail.png" alt="Mail" data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="1500" />
            <h2 data-aos="zoom-in-down" data-aos-duration="2000" data-aos-delay="1500">thibaultdtpro@gmail.com</h2>
          </div>

          <div className="contact-block">
  <a href="https://www.linkedin.com/in/thibault-delattre/" target="_blank" rel="noopener noreferrer">
    <img className="icon" src="/linkedin.png" alt="Linkedin" data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="1500" />
    <h2 data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="1500">Thibault-Delattre</h2>
  </a>
</div>


          <div className="icons-container">
            <img className="flags" src={frenchIcon} alt="French" data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="1500" />
            <img className="flags" src={englishIcon} alt="English" data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="1500" />
          </div>
          </div>

        </main>
      </div>
    );
  }
  
  export default Contact;
  