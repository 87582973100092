import React from 'react';
import './Loader.scss';

const Loader = ({ show }) => {
  const className = `loading ${show ? '' : 'fade-out'}`; // Condition pour appliquer la classe fade-out uniquement lorsque show est true
  return (
    <div className={className}>
      <div className='logo'></div>
      <div className='dots animate'>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
      </div>
    </div>
  );
};

export default Loader;
