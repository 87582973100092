import React, { useEffect, useRef, useState } from "react";
import { Tilt } from "react-tilt";
import AOS from "aos";
import "aos/dist/aos.css";
import "./About.scss";
import Navbar from "./Navbar";
import { useTranslation } from 'react-i18next';

function About() {
  const { t, i18n } = useTranslation();

  useEffect(() => { 
    AOS.init({ offset: -3600 });
  }, []);

  return (
    <div className="about-page">
      <Navbar />
      <main>






      <div className="particle particle-1" data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="1500"></div>
        <div className="particle particle-2"data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="2000"></div>
        <div className="particle particle-3"data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="2500"></div>
        <div className="particle particle-4"data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="3000"></div>
        <div className="containerleft">
        <div className="intro-section">
          <Tilt className="card" options={{ max: 25, scale: 1.05 }}>
            <div
              className="bg"
              data-aos="zoom-in-up"
              data-aos-duration="1500"
              data-aos-delay="1000"
            ></div>
          </Tilt>
          <div className="introduction">
            <p className="intro-title" data-aos="zoom-in-right" data-aos-duration="1600" data-aos-delay="1100">{t('about')}</p>
            <p className="intro-paragraph" data-aos="zoom-in-right" data-aos-duration="2700" data-aos-delay="1100">
            {t('intro')}
            </p>
          </div>

        </div>
        <div className="secondpart">
        <div className="whiteline" data-aos="zoom-in-right" data-aos-duration="2700" data-aos-delay="1100"></div>
        <h2 className="titleExp" data-aos="zoom-in-right" data-aos-duration="2700" data-aos-delay="1100">{t('skillit')}</h2>

        <div className="expimgContainer">
          <div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="100" >
          <img src="/html.png" alt="HTML5" className="image-class"/>
      </div>
      <div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="200">
  <img src="/css.png" alt="CSS3" className="image-class"/>
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="300">
  <img src="/sass.png" alt="Sass" className="image-class"/>
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="400">
  <img src="/bootstrap.png" alt="Bootstrap" className="image-class"/>
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="500">
  <img src="/tailwind.png" alt="Tailwind" className="image-class"/>
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="600">
  <img src="/php.png" alt="PHP" className="image-class"/>
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="700">
  <img src="/laravel.png" alt="Laravel" className="image-class"/>
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="800">
  <img src="/symfony.png" alt="Symfony" className="image-class"/>
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="900">
  <img src="/js.svg" alt="JavaScript" className="image-class"/>
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1000">
  <img src="/ts.svg" alt="TypeScript" className="image-class"/>
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1100">
  <img src="/react.png" alt="React Native" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1200">
  <img src="/aframe.png" alt="A-Frame" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1300">
  <img src="/threejs.png" alt="Three.js" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1400">
  <img src="/nodejs.png" alt="Node.js" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1500">
  <img src="/gsap.svg" alt="GSAP" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1600">
  <img src="/vue.png" alt="Vue" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1700">
  <img src="/jquery.svg" alt="jQuery" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1800">
  <img src="/backbone.png" alt="Backbone" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1900">
  <img src="/marionette.png" alt="Marionette" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="2000">
  <img src="/git.svg" alt="Git" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="2100">
  <img src="/express.png" alt="Express" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="2200">
  <img src="/csharp.png" alt="C#" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="2300">
  <img src="/docker.png" alt="Docker" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="2400">
  <img src="/python.png" alt="Python" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="2500">
  <img src="/opencv.png" alt="OpenCV" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="2600">
  <img src="/tensorflow.png" alt="TensorFlow" className="image-class" />
</div>


</div>


<h2 className="titleExp" data-aos="zoom-in-right" data-aos-duration="2700" data-aos-delay="1100">{t('skillmultimedia')}</h2>

<div className="expimgContainer">
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="100">
  <img src="/adobe-after-effects.png" alt="Adobe After Effects" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="200">
  <img src="/adobe-photoshop.png" alt="Adobe Photoshop" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="300">
  <img src="/blender-3d.png" alt="Blender 3D" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="400">
  <img src="/audacity.png" alt="Audacity" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="500">
  <img src="/sony-vegas.png" alt="Sony Vegas" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="600">
  <img src="/spline.png" alt="Spline" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="700">
  <img src="/unity.png" alt="Unity" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="800">
  <img src="/filezilla.png" alt="filezilla" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="900">
  <img src="/godot.png" alt="godot" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1000">
  <img src="/appwrite.png" alt="appwrite" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1100">
  <img src="/excel.png" alt="excel" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1200">
  <img src="/expogo.png" alt="expogo" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1300">
  <img src="/wordpress.png" alt="wordpress" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1400">
  <img src="/figma.png" alt="figma" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1500">
  <img src="/aseprite.png" alt="aseprite" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1600">
  <img src="/ibis-paint-x.png" alt="Ibis Paint X" className="image-class" />
</div>
<div data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="1700">
  <img src="/krita-squared.png" alt="Krita" className="image-class" />
</div>

</div>

    
        <div className="whiteline" data-aos="zoom-in-right" data-aos-duration="2700" data-aos-delay="1100"></div>
        <h2 className="titleExp" data-aos="zoom-in-right" data-aos-duration="1600" data-aos-delay="1700">{t('certifications')}</h2>
        <p data-aos="zoom-in-right" data-aos-duration="1600" data-aos-delay="1800">Toefl IBT Score : 92</p>
        <p data-aos="zoom-in-right" data-aos-duration="1600" data-aos-delay="1900">FreeCodeCamp : Responsive Web Design (HTML, CSS, Flexbox, Grid)</p>
        <p data-aos="zoom-in-right" data-aos-duration="1600" data-aos-delay="2000">FreeCodeCamp : Algorithms and data structures (Javascript)</p>

        <div className="whiteline2" data-aos="zoom-in-right" data-aos-duration="2700" data-aos-delay="1100"></div>
        <h2 className="titleExp" data-aos="zoom-in-right" data-aos-duration="1600" data-aos-delay="2100">{t('hobbies')}</h2>
        <p data-aos="zoom-in-right" data-aos-duration="1600" data-aos-delay="2200">{t('football')}</p>
        <p data-aos="zoom-in-right" data-aos-duration="1600" data-aos-delay="2300">{t('badminton')}</p>
        <p data-aos="zoom-in-right" data-aos-duration="1600" data-aos-delay="2400">{t('f1')}</p>
        <p data-aos="zoom-in-right" data-aos-duration="1600" data-aos-delay="2500">{t('voyage')}</p>
        <p data-aos="zoom-in-right" data-aos-duration="1600" data-aos-delay="2600">{t('photographie')}</p>
        <p data-aos="zoom-in-right" data-aos-duration="1600" data-aos-delay="2700">{t('arts')}</p>
        <p data-aos="zoom-in-right" data-aos-duration="1600" data-aos-delay="2800">{t('musique')}</p>

        <div className="whiteline2" data-aos="zoom-in-right" data-aos-duration="2700" data-aos-delay="1100"></div>

        </div>
        </div>

        <div className="containerright">
        <div className="work">
            <h1 data-aos="zoom-in-right" data-aos-duration="1600" data-aos-delay="1100">{t('experience')}</h1>

<div className="year">
  <div className="yearbanner">
    <div className="yearbannertext">
      <h2 data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="1100">{t('apprentissage')}</h2>
      <h3 data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="1200">Vélizy, France</h3>
    </div>
    <div data-aos="zoom-in-left" data-aos-duration="2000" data-aos-delay="1100">
    <img  src="/dassault.png" alt="Dassault"/>
    </div>

    </div>
    <p data-aos="zoom-in-right" data-aos-duration="2700" data-aos-delay="1100">
    {t('apprentissagel1')}<br></br>
    {t('apprentissagel2')}<br></br>
    {t('apprentissagel3')}
    </p>
</div>

<div className="year">
  <div className="yearbanner">
    <div className="yearbannertext">
      <h2 data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="1500">{t('stage')} </h2>
      <h3 data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="1600">Akita, Japon</h3>
    </div>
    <div data-aos="zoom-in-left" data-aos-duration="2000" data-aos-delay="1500">
    <img src="/kosen.png" alt="Akita Kosen"/>
    </div>
    </div>
    <p data-aos="zoom-in-right" data-aos-duration="2700" data-aos-delay="1500">
    {t('stagel1')}<br></br>
    {t('stagel2')}<br></br>
    {t('stagel3')}<br></br>
    {t('stagel4')}
    </p>
</div>

<div className="year">
  <div className="yearbanner">
    <div className="yearbannertext">
      <h2 data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="1900">{t('summerintern')}</h2>
      <h3 data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="2000">Blois, France</h3>
    </div>
    <div data-aos="zoom-in-left" data-aos-duration="2000" data-aos-delay="1900">
      <img src="/sg.png" alt="Société Générale"/>
    </div>
    </div>
    <p data-aos="zoom-in-right" data-aos-duration="2700" data-aos-delay="1900">
    {t('summerinternl1')} <br></br>
    {t('summerinternl2')}<br></br>
    {t('summerinternl3')}
    </p>
    <div className="whiteline2" data-aos="zoom-in-left" data-aos-duration="2700" data-aos-delay="1100"></div>
</div>







          </div>
          
          <div className="study">
            
            <h1 data-aos="zoom-in-right" data-aos-duration="1600" data-aos-delay="1100">{t('formation')}</h1>

<div className="year">
  <div className="yearbanner">
    <div className="yearbannertext">
      <h2 data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="2300">2021 - 2024</h2>
      <h3 data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="2400">Blois, France</h3>
    </div>
    <div data-aos="zoom-in-left" data-aos-duration="2000" data-aos-delay="2300">
      <img src="/mmi.png" alt="BUT MMI Blois"/>
    </div>
    </div>
    <p data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="2300">
    {t('formationl1')}<br></br>
    {t('formationl2')}<br></br>
    {t('formationl3')}<br></br>
    {t('formationl4')}<br></br>
    {t('formationl5')}<br></br>
    {t('formationl6')}<br></br>
    {t('formationl7')}<br></br>
    {t('formationl8')}<br></br>
    {t('formationl9')}<br></br>
    {t('formationl10')}<br></br>
    {t('formationl11')}<br></br>
    {t('formationl12')}<br></br>
    {t('formationl13')}<br></br>
    {t('formationl14')}<br></br>
    </p>
</div>

<div className="year">
  <div className="yearbanner">
    <div className="yearbannertext">
      <h2 data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="2700">2014 - 2021 </h2>
      <h3 data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="2800">Bastia, France</h3>
    </div>
    <div data-aos="zoom-in-left" data-aos-duration="2700" data-aos-delay="2700">
    <img src="/jda.jpg" alt="Jeanne d'Arc"/>
    </div>

    </div>
    <p data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="2700">
    {t('jdal1')}<br></br>
    {t('jdal2')}<br></br>
    {t('jdal3')}<br></br>
    </p>
</div>







          </div>
          
        </div>
      </main>
    </div>
  );
}

export default About;
